
export default {
  name: 'ExamplesBlok',
  components: {
    VueMarkdown: () => import('@adapttive/vue-markdown'),
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    selection: {
      text: '',
      input: '',
      output: '',
    },
  }),
  computed: {
    items() {
      return this.blok.items
    },
    href() {
      if (!this.blok.route) return
      return this.blok.route
        .replace('{baseURL}', this.$config.baseURL)
        .replace('{appURL}', this.$config.appURL)
        .replace('{url}', this.$config.url)
    },
  },
  created() {
    this.selection = this.items[0]
  },
}
